
import Vue from "vue";

import { Agreement } from "@/interfaces";

import http from "@/http";

export interface DataType {
  agreement?: Agreement;
}

export default Vue.extend({
  data(): DataType {
    return {
      agreement: undefined,
    };
  },
  computed: {
    id(): number {
      const { id } = this.$route.params;

      return +id;
    },
  },
  watch: {
    async $route() {
      await this.fetch();
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      const url = `/agreements/${this.id}`;

      const { data } = await http.get<Agreement>(url);

      this.agreement = data;
    },
  },
});
